import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, BookList, Button, Video } from '../../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="Learn English Alphabets with Words" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">Learn English Alphabets with Words</Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/study-materials/',
        name: 'শিক্ষাপোকরণ'
      }, {
        url: '/study-materials/english',
        name: 'ইংরেজী'
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <br />
      <br />
      <ul>
        <li parentName="ul">
          <a href="https://docs.google.com/presentation/d/1dCC7P-9gUs3Hy4dOR0vpXRdlg5LOzQ4B/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true" target="_blank">A to D for kids</a>
        </li>
        <li parentName="ul">
          <a href="https://docs.google.com/presentation/d/1GR818KWE6xcvpNSVt_y7farJb5oojWuv/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true" target="_blank">E to H for kids</a>
        </li>
        <li parentName="ul">
          <a href="https://docs.google.com/presentation/d/1aVn2AhQd1v559PNFCt9zXJg2gaeqKeRW/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true" target="_blank">I to L for kids</a>
        </li>
        <li parentName="ul">
          <a href="https://docs.google.com/presentation/d/15bMQEOhbTnfV47N4RN-NlBaSHhoC8InB/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true" target="_blank">M to P for kids</a>
        </li>
        <li parentName="ul">
          <a href="https://docs.google.com/presentation/d/1fCuEJREFCsjNBl6HyaZGmrI5OBcUC2uo/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true" target="_blank">Q to T for kids</a>
        </li>
        <li parentName="ul">
          <a href="https://docs.google.com/presentation/d/1GEBB_zn7zud5yJYJxaks0TjKsiEiQ4ON/edit?usp=drive_link&ouid=106179897632712562234&rtpof=true&sd=true" target="_blank">U to W for kids</a>
        </li>
        <li parentName="ul">
          <a href="https://docs.google.com/presentation/d/1cM2BUZYd5XMgOfNwNey5WLETaVWwO3A_/edit?usp=drive_link&ouid=106179897632712562234&rtpof=true&sd=true" target="_blank">X to Z for kids</a>
        </li>
      </ul>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      